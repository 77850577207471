$(function() {

	$( '#menu_bars' ).on('click', function() {

		if ($(this).hasClass('active')) {

			$('.menu').removeClass('slide-in');
			$(this).removeClass('active');
			$('body').removeClass('no_scroll');

		} else {

			$('.menu').addClass('slide-in');
			$(this).addClass('active');
			$('body').addClass('no_scroll');

		}

	});


	$('.menu a').on('click', function(event) {
		var url = $(this).attr('href');
		if ( url.charAt(0) === '#' ) {
			event.preventDefault();
			scrollToElement( $(url) );
		}
	});

});