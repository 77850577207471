/**
 * Created by filippo on 10/04/2019.
 */

$(function() {

	$('.privacy_text').on('scroll', function() {
		var offset = $(this).outerHeight();
		if ( this.scrollHeight <= ( this.scrollTop+offset ) ) {
			$(this).addClass('scrolled');
			$('#form-group-privacy').find('input').removeAttr('disabled');
			$('#form-group-privacy2').find('input').removeAttr('disabled');
			$('#form-group-contract_conditions').find('input').removeAttr('disabled');
		}
	});

	$('.discover_more').on('click', function(e){
		e.preventDefault();
		scrollToElement($($(this).attr('href')));
	})

	if ( $('#cover-slider').length ) {

		$('#cover-slider').slick({
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			autoplay: true,
			autoplaySpeed: 4000
		});

	}

});

