/**
 * Created by filippo on 10/04/2019.
 */


var lastPlanPeriodSelected = 'month';

$(function() {

	function checkPlanPeriod( $input ) {
		var val = $input.val();

		if ( val === 'year' || val.indexOf('year') != -1 ) {
			$('.yearly_plan').removeClass('hidden');
			$('.monthly_plan').addClass('hidden');
		} else {
			$('.monthly_plan').removeClass('hidden');
			$('.yearly_plan').addClass('hidden');
		}
	}

	$('.plans-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		var $tab = $( $(e.target).attr('href') ) ;
		if (lastPlanPeriodSelected === 'month'){
            $( $tab.find('input[name="plan_period"]')[0] ).prop('checked', 'checked');
        }
        else{
            $( $tab.find('input[name="plan_period"]')[1] ).prop('checked', 'checked');
        }
	});

	$(window).on('load', function() {

		if ( $('.tab-pane.active').length > 0 ) {

			$( $('.tab-pane.active').find('input[name="plan_period"]')[0] ).prop('checked', 'checked');

		} else if ( $('input[name="plan_period"]:checked').length > 0 ) {

			checkPlanPeriod( $('input[name="plan_period"]:checked') );

		} else if ( $('input[name="plan"]:checked').length > 0 ) {

			checkPlanPeriod( $('input[name="plan"]:checked') );

		}

	});

	$( 'input[name="plan_period"], input[name="plan"]' ).on('change', function() {
		checkPlanPeriod( $(this) );
        lastPlanPeriodSelected = $(this).val();
	});

	$('.radio-card label').on('click', function(event) {

		$('.radio-card').removeClass('active');
		$(this).closest('.radio-card').addClass('active');

		var $plan = $(this).find('input[type="radio"]:checked');
		if ( $plan ) {
			var val = $plan.val(),
				$sel = $('.plan-details.plan-' + val);

			$('.plan-details').addClass('hidden');
			$sel.removeClass('hidden');

			// if ( $sel.length > 0 ) {
			// 	scrollToElement( $sel );
			// }
		}
	});

	$('.product-card .cta').on('click', function(event) {

		if ( $('.plans-tabs a[href="' + $(this).attr('href') + '"]').length > 0 ) {
			event.preventDefault();
			$('.plans-tabs a[href="' + $(this).attr('href') + '"]').tab('show');
		}

		if ( $(this).attr('href').charAt(0) == '#' && $($(this).attr('href')).length > 0 ) {
			event.preventDefault();
			scrollToElement( $($(this).attr('href')) );
		}
	});

});

